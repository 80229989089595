//import axios from "axios";

import axios from './axiosConfig';


import { createBrowserHistory } from "history";

//import { WonderPush } from 'react-wonderpush';
//window.WonderPush = window.WonderPush || [];
//WonderPush.push(['setUserId', "THIS_USER_ID"]);



//const API_URL = "http://localhost:5551";
const API_URL = "https://api.filmica.ai";

const history = createBrowserHistory();





export const importScreenplay = (name, author, file) => {
  return (dispatch) => {

    dispatch({ type: "RESET_ERRORS" });

    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', name);
    formData.append('author', author);

    axios
      .post(API_URL + "/import_screenplay", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      .then(({ data }) => {

        dispatch({ type: "NEW_SCREEN_ID", d: data.screen_id });

      }).catch((error) => {
        if (error.response) {

          if (error.response.status === 400) {

            dispatch({ type: "ERROR_IN_STEP", what: "Internal error, please try again." });

          }

          else {
            dispatch({ type: "ERROR_IN_STEP", what: "Internal error, please try again." });

          }
        }
      });
  };
};


export const createScreenplay = (name, author) => {
  return (dispatch) => {

    dispatch({ type: "RESET_ERRORS" });


    axios
      .post(API_URL + "/create_screenplay", { name, author })
      .then(({ data }) => {
        //dispatch({ type: "SENT_MESSSAGE" });
        //dispatch({ type: "UPDATEPROF", d: data[0] });

        dispatch({ type: "NEW_SCREEN_ID", d: data.screen_id });




      }).catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 400) {

            dispatch({ type: "ERROR_IN_STEP", what: "Internal error, please try again." });

          }

          else {
            dispatch({ type: "ERROR_IN_STEP", what: "Internal error, please try again." });

          }
        }
      });
  };
};

export const archiveScreenplay = (id) => {
  return (dispatch) => {

    axios
      .post(API_URL + "/archive_screenplay", { id })
      .then(({ data }) => {

        dispatch({ type: "ARCHIVED", d: true });

      }).catch((error) => {

        dispatch({ type: "ARCHIVED", d: true });

      });
  };
};






export const editProductionData = (id, content, what) => {
  return (dispatch) => {

    axios
      .post(API_URL + "/edit_production_data", { id, content, what })
      .then(({ data }) => {

        dispatch({ type: "PRODUCTION_DATA_EDIT", d: true });

      }).catch((error) => {

        dispatch({ type: "PRODUCTION_DATA_EDIT", d: true });

      });
  };
};


export const editBudget = (id, content) => {
  return (dispatch) => {

    axios
      .post(API_URL + "/edit_budget", { id, content })
      .then(({ data }) => {

        dispatch({ type: "BUDGET_EDIT", d: true });

      }).catch((error) => {

        dispatch({ type: "BUDGET_EDIT", d: true });

      });
  };
};


export const editScreenplay = (id, content, title) => {
  return (dispatch) => {

    axios
      .post(API_URL + "/edit_screenplay", { id, content, title })
      .then(({ data }) => {

        dispatch({ type: "SCREENPLAY_EDIT", d: true });

      }).catch((error) => {

        dispatch({ type: "SCREENPLAY_EDIT", d: true });

      });
  };
};




export const saveSettings = (company, full_name, address, country, canva_api) => {
  return (dispatch) => {

    axios
      .post(API_URL + "/save_settings", { company, full_name, address, country, canva_api })
      .then(({ data }) => {

        dispatch({ type: "SAVED_SETTINGS", d: true });

      }).catch((error) => {

        dispatch({ type: "SAVED_SETTINGS", d: true });

      });
  };
};





export const saveSettings_admin = (company, full_name, address, country, credits, is_admin, id) => {
  return (dispatch) => {

    axios
      .post(API_URL + "/save_settings_admin", { company, full_name, address, country, credits, is_admin, id })
      .then(({ data }) => {

        dispatch({ type: "SAVED_SETTINGS", d: true });

      }).catch((error) => {

        dispatch({ type: "SAVED_SETTINGS", d: true });

      });
  };
};

export const cancelStripe = () => {
  return (dispatch) => {
    axios
      .post(API_URL + "/cancel_stripe", {})
      .then(({ data }) => {
        dispatch({ type: "CANCELLED", d: true });
      })
  };
};


export const saveSettings_admin2 = (credits_monthly, credits_yearly, monthly_award, yearly_award, price_1, price_2, price_3) => {
  return (dispatch) => {

    axios
      .post(API_URL + "/save_settings_admin2", { credits_monthly, credits_yearly, monthly_award, yearly_award, price_1, price_2, price_3 })
      .then(({ data }) => {

        dispatch({ type: "SAVED_SETTINGS", d: true });

      }).catch((error) => {

        dispatch({ type: "SAVED_SETTINGS", d: true });

      });
  };
};


export const getSettingsAdmin2 = () => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_general_settings")
      .then(({ data }) => {
        dispatch({ type: "GENERAL_SETTINGS", d: data });
      });
  };
};




export const getPages = (id, key) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_pdf_preview?id=" + id + "&key=" + key)
      .then(({ data }) => {
        dispatch({ type: "PAGES", d: data.pages });
      });
  };
};

export const getSettingsAdmin = (id) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_settings_admin?id=" + id)
      .then(({ data }) => {
        dispatch({ type: "SETTINGS_ADMIN", d: data });
      });
  };
};

export const getSettings = () => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_settings")
      .then(({ data }) => {
        dispatch({ type: "SETTINGS", d: data });
      });
  };
};

export const getBalance = () => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_balance")
      .then(({ data }) => {
        dispatch({ type: "BALANCE", d: data });
      })
  };
};


export const getPrice = () => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_price")
      .then(({ data }) => {
        dispatch({ type: "PRICE", d: data });
      })
  };
};


export const getStripe = (price, amount, method) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/buy_credit?quantity=" + amount + "&amount=" + price + "&method=" + method)
      .then(({ data }) => {
        dispatch({ type: "REDIRECT_NOW", d: data });
      })
  };
};


export const getReferrals = (a, b) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_referrals")
      .then(({ data }) => {
        dispatch({ type: "REFERRALS", d: data.response });
      });
  };
};




export const getTXs = (a, b) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_billing")
      .then(({ data }) => {
        dispatch({ type: "TXS", d: data.response });
      });
  };
};

export const getTXsAdmin = (a, b) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_billing_admin")
      .then(({ data }) => {
        dispatch({ type: "TXS_ADMIN", d: data.response });
      });
  };
};


export const getScreenplays = (a, b) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_screenplays")
      .then(({ data }) => {
        dispatch({ type: "SCREENPLAYS", d: data.response });
      });
  };
};

export const getScreens_admin = (id) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_screens_admin?id=" + id)
      .then(({ data }) => {
        dispatch({ type: "SCREENS_ADMIN", d: data.response });
      });
  };
};



export const getLogs = (a, b) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_logs")
      .then(({ data }) => {
        dispatch({ type: "LOGS", d: data.response });
      });
  };
};

export const getUsersAdmin = (a, b) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_users_admin")
      .then(({ data }) => {
        dispatch({ type: "USERS", d: data.response });
      });
  };
};



export const getAnalytics = (arr) => {




  return (dispatch) => {



    if (!arr || arr.length < 1) {
      return dispatch({ type: "STATS", d: [] });
    }

    let from = arr[0];
    let to = arr[1];

    axios
      .get(API_URL + "/get_analytics?from=" + from + "&to=" + to)
      .then(({ data }) => {
        dispatch({ type: "ANALYTICS", d: data });
      });
  };
};

export const getStats = (a, b) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_stats")
      .then(({ data }) => {
        dispatch({ type: "STATS", d: data });
      });
  };
};

export const getFullBudget = (id) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_budget_pdf?id=" + id, {
        responseType: 'blob', // Important for handling binary data
      })

      .then(({ response }) => {

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const contentDisposition = response.headers['content-disposition'];
        let filename = 'download.pdf';

        if (contentDisposition) {
          const match = contentDisposition.match(/filename="?(.+)"?/);
          if (match.length > 1) {
            filename = match[1];
          }
        }

        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);


        dispatch({ type: "PDF_BUDGET", d: true });
      }).catch((error) => {

        dispatch({ type: "ERROR_PDF_BUDGET", d: true });

      });
  };
};

export const getImage = (id) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_images?id=" + id)
      .then(({ data }) => {
        dispatch({ type: "IMAGES", d: data });
      });
  };
};

export const makeImage = (prompt, screenid, sceneid) => {
  return (dispatch) => {
    axios
      .post(API_URL + "/generate_image", { prompt, screenid, sceneid })

      .then(({ data }) => {
        dispatch({ type: "IMAGES", d: data });
      }).catch((error) => {

        dispatch({ type: "ERROR_MAKING_IMAGE", d: true });

      });
  };
};


export const sendFeedback = (email, name, message) => {
  return (dispatch) => {
    axios
      .post(API_URL + "/feedback", { email, name, message })

      .then(({ data }) => {
        // dispatch({ type: "BREAKDOWNSHEET_READY", d: data });
        dispatch({ type: "FEEDBACK", d: true });



      }).catch((error) => {
        dispatch({ type: "FEEDBACK", d: true });

      });
  };
};




export const makeSheet = (scene_id, productionno, productiontitle, breakdownpageno, sceneno, scenename, scriptpageno, description, pagecount, locationname, screen, title) => {
  return (dispatch) => {
    axios
      .post(API_URL + "/get_budget_pdf_single_editable", { scene_id, productionno, productiontitle, breakdownpageno, sceneno, scenename, scriptpageno, description, pagecount, locationname, screen }, { responseType: "blob" })

      .then(({ data }) => {
        // dispatch({ type: "BREAKDOWNSHEET_READY", d: data });
        dispatch({ type: "BREAKDOWNSHEET_READY", d: true });
        const blob = new Blob([data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${title.toLowerCase() // Convert to lowercase
          .replace(/[^a-z0-9\s]/g, '') // Remove special characters
          .trim() // Remove leading/trailing spaces
          .replace(/\s+/g, '-')}-${sceneno}.pdf`; // Customize the file name
        link.click();


      }).catch((error) => {
        dispatch({ type: "BREAKDOWNSHEET_READY", d: true });

      });
  };
};

export const makeAI = (prompt, keytype, key) => {
  return (dispatch) => {
    axios
      .post(API_URL + "/ai_assistant", { prompt, keytype, key })

      .then(({ data }) => {
        dispatch({ type: "AI", d: data });
      }).catch((error) => {

        dispatch({ type: "ERROR_MAKING_AI", d: true });

      });
  };
};

export const getBreakdowns = () => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_breakdowns_all")
      .then(({ data }) => {
        dispatch({ type: "BREAKDOWNS_ALL", d: data });
      });
  };
};


export const getBudgets = () => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_budgets_all")
      .then(({ data }) => {
        dispatch({ type: "BUDGETS_ALL", d: data });
      });
  };
};

export const getBudget_single = (id) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_budget_single?id=" + id)
      .then(({ data }) => {
        dispatch({ type: "BUDGETS_SINGLE", d: data });
      });
  };
};


export const getProductionData = (id) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_production_data?id=" + id)
      .then(({ data }) => {
        dispatch({ type: "PRODUCTION_DATA", d: data });
      });
  };
};


export const getBudget = (id) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_budgets?id=" + id)
      .then(({ data }) => {
        dispatch({ type: "BUDGETS", d: data });
      });
  };
};

export const makeBudget = (id, what) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/make_budgets?id=" + id + "&what=" + what)
      .then(({ data }) => {
        dispatch({ type: "BUDGETS", d: data });
      }).catch((error) => {

        dispatch({ type: "ERROR_MAKING_BUDGET", d: true });

      });
  };
};


export const getScrenPlay = (id) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_screenplay?id=" + id)
      .then(({ data }) => {
        dispatch({ type: "SCREENPLAY", d: data });
      });
  };
};








export const getUser = (id) => {
  return (dispatch) => {
    axios
      .get(
        API_URL + "/getuser?id=" + id
      )
      .then(({ data }) => {
        dispatch({ type: "USER_PROFILE_DATA", d: data });
      });
  };
};






export const recover = (username) => {
  return (dispatch) => {

    dispatch({ type: "RESET_ERRORS" });

    axios
      .post(API_URL + "/recovery", { email: username })
      .then(({ data }) => {
        if (data.error) {
          dispatch({ type: "ERROR_RECOVERY" });
        } else {
          dispatch({ type: "RECOVERED", recovered: true });
        }
      });
  };
};



export const loginGoogle = (cred, ref) => {
  return (dispatch) => {


    dispatch({ type: "RESET_ERRORS" });

    //alert(pwd);

    axios
      // .get(API_URL + "/check_username?username=" + username)
      .post(API_URL + "/auth/google", { token: cred, referral_code_browser: ref })

      .then(({ data }) => {

        //alert(data.token);

        dispatch({ type: "LOG_USER_IN", token: data.tokenm });
        history.push('/screenplays');


      }).catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 400) {

            dispatch({ type: "ERROR_EMAIL_NOT_EXIST" });

          }

          else if (error.response.status === 401) {

            dispatch({ type: "ERROR_PWD_LOGIN" });

          }


          else if (error.response.status === 402) {

            dispatch({ type: "ERROR_LOGGIN_IN" });

          }

        };

      });

  };
};



export const login = (username, pwd) => {
  return (dispatch) => {


    dispatch({ type: "RESET_ERRORS" });

    //alert(pwd);

    axios
      // .get(API_URL + "/check_username?username=" + username)
      .post(API_URL + "/login", { email: username, password: pwd })

      .then(({ data }) => {

        //alert(data.token);

        dispatch({ type: "LOG_USER_IN", token: data.token });
        history.push('/screenplays');


      }).catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 400) {

            dispatch({ type: "ERROR_EMAIL_NOT_EXIST" });

          }

          else if (error.response.status === 401) {

            dispatch({ type: "ERROR_PWD_LOGIN" });

          }


          else if (error.response.status === 402) {

            dispatch({ type: "ERROR_LOGGIN_IN" });

          }

        };

      });

  };
};


export const loginforced = (id) => {
  return (dispatch) => {

    //dispatch({ type: "RESET_ERRORS" });
    //autologin
    dispatch({ type: "LOG_USER_IN", token: id });
    window.location.href = "/screenplays"
  };
};

export const resetCreationBudget = () => {
  console.log("resetting...");
  return (dispatch) => {

    dispatch({ type: "RESET_CREATION_BUDGET" });

  }
};


export const resetCreation = () => {
  console.log("resetting...");
  return (dispatch) => {

    dispatch({ type: "RESET_CREATION" });

  }
};




export const resetErrors = () => {
  return (dispatch) => {

    dispatch({ type: "RESET_ERRORS" });

  }
};


export const checkUsername = (username, name, password1, password2, ref) => {
  return (dispatch) => {


    dispatch({ type: "RESET_ERRORS" });


    axios
      // .get(API_URL + "/check_username?username=" + username)
      .post(API_URL + "/register", { email: username, name, password1, password2, referral_code_browser: ref })

      .then(({ data }) => {

        //alert(data.token);

        dispatch({ type: "LOG_USER_IN", token: data.token });
        history.push('/screenplays');


      }).catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 400) {

            dispatch({ type: "ERROR_LOGGING_USERNAME" });

          }

          else if (error.response.status === 401) {

            dispatch({ type: "ERROR_PWD" });

          }


          else if (error.response.status === 402) {

            dispatch({ type: "ERROR_EMAIL" });

          }

        };

      });

  }

};


export const logOut = (pwd) => {
  return (dispatch) => {
    dispatch({ type: "LOGGEDOUT" });
  };
};

export const getBreakdownsForScreen = (id) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_breakdowns_for_screen?id=" + id)
      .then(({ data }) => {
        dispatch({ type: "BREAKDOWNS_FOR_SCREEN", d: data });
      });
  };
};
