import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getScreenplays, archiveScreenplay, resetCreation, getBudgets, getBreakdowns } from "../store/actions/Actions";
import { Redirect } from 'react-router-dom';

import DataTable from 'react-data-table-component';




function Breakdowns(props) {

  const { token, loading, getBreakdownsFN, breakdowns_all } = props;

  const [selectedScenes, setSelectedScenes] = useState('');




  const handleDownload = (selectedScreen) => {
    if (selectedScenes) {

      // console.log(selectedScenes);

      window.open(`https://api.filmica.ai/get_pdf_breakdown?id=${selectedScenes}&screen=${selectedScreen}&userid=${breakdowns_all[0]['user']}`)

    }
  };


  //const [clicked_analytics, setclicked_analytics] = useState([]);


  //const [totalRows, setTotalRows] = useState(0);


  // const canvaRef = useRef();
  //const anaRef = useRef();


  useEffect(() => {



    getBreakdownsFN();


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);





  //console.log(breakdowns_all[0].scenes);
  const columns = [
    {
      name: 'Screenplay Title',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{row.title}</p>),
      sortable: true,
      width: '40%',
    },
    {
      name: 'Actions',
      width: '60%',
      selector: row => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <select
            style={{ height: "30px", padding: "5px" }}
            onChange={(e) => setSelectedScenes(e.target.value)}
          >
            <option value="">Select a scene to download</option>

            {/* Find the breakdown object that matches the row */}
            {breakdowns_all &&
              breakdowns_all.length > 0 &&
              breakdowns_all
                .filter((item) => item.title === row.title) // Adjust this condition to match your row uniquely (e.g., by screen or user if more appropriate)
                .flatMap((item) =>
                  item.scenes.map((scene, index) => (
                    <option key={index} value={scene}>
                      {`Scene ${scene}`}
                    </option>
                  ))
                )}

            {/* Check if there are multiple scenes and add an "ALL" option */}
            {breakdowns_all &&
              breakdowns_all.length > 0 &&
              breakdowns_all.some((item) => item.title === row.title && item.scenes.length > 1) ? (
              <option value="all">ALL</option>
            ) : null}
          </select>
          <button
            style={{
              padding: '5px 10px',
              fontSize: '1rem',
              fontWeight: '600',
              border: 0,
              color: '#fff',
              background: '#000',
              borderRadius: '3px',
            }}
            onClick={() => {
              //return alert(row.screen);
              //setselectedScreen(row.screen);
              handleDownload(row.screen)
            }} // Pass the title or any unique identifier to handleDownload
          >
            Download
          </button>
        </div>
      ),
      sortable: true,
    }
  ];


  return token ? (
    <div className="container" style={{ marginTop: "90px" }}>

      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column"
          style={{
            color: "black",
            padding: "10px !important",
            //border: "2px solid #FECB00",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.2)',


          }}
        >

          {breakdowns_all === undefined || !breakdowns_all || breakdowns_all.length < 1 ? <p>You haven't created any breakdown sheet yet. You can create one on a given Screenplay view.</p> : <DataTable
            title="My Breakdown Sheets"
            columns={columns}
            data={breakdowns_all}
            progressPending={loading}
            pagination
            //paginationServer
            //paginationTotalRows={totalRows}
            //onChangeRowsPerPage={handlePerRowsChange}
            //onChangePage={handlePageChange}


            paginationRowsPerPageOptions={[10, 20, 30]}
            paginationPerPage={10} // Set the default number of rows per page
            paginationTotalRows={breakdowns_all.length} // Total number of rows in your data
            paginationDefaultPage={1}



          />}

        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2024 filmica</p>
      </div>








    </div >) : <Redirect to="/login" />;

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    loading: state.home.loading,
    balance: state.home.balance,
    budgets_all: state.home.budgets_all,
    breakdowns_all: state.home.breakdowns_all





  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getScreenplaysFN: (a, b) =>
      dispatch(getScreenplays(a, b)),
    archiveScreenplayFN: (id) =>
      dispatch(archiveScreenplay(id)),

    resetCreationFN: () =>
      dispatch(resetCreation()),
    getBudgetsFN: () =>
      dispatch(getBudgets()),

    getBreakdownsFN: () =>
      dispatch(getBreakdowns()),



  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(Breakdowns);
