import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getProductionData, editProductionData, resetCreation } from "../store/actions/Actions";
import { Redirect } from 'react-router-dom';

//import { useHistory } from 'react-router-dom';
import LOADER from "../assets/images/loader.svg";

//import Spreadsheet from "react-spreadsheet";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { HotTable, HotColumn } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.css';

import { HyperFormula } from 'hyperformula';

import { registerAllModules } from 'handsontable/registry';

// register Handsontable's modules
registerAllModules();


function AdminData(props) {

  const { token, resetCreationFN, match, production_data, production_data_edited, getProductionDataFN, editProductionDataFN } = props;

  const [arra, setarra] = useState([]);
  const [arra_mx, setarra_mx] = useState([]);

  const [issaving, setissaving] = useState(false);
  const [what, setwhat] = useState("us");


  //const history = useHistory();

  const hyperformulaInstance = HyperFormula.buildEmpty({
    // to use an external HyperFormula instance,
    // initialize it with the `'internal-use-in-handsontable'` license key
    licenseKey: 'internal-use-in-handsontable',
  });



  //const columnLabels = ["ACCOUNT NO", "DESCRIPTION", "QUANTITY", "FREQUENCY", "RATE", "SUBTOTAL", "SUBTOTAL($)", "NOTES"];


  const hotData = arra.map(row => row.map(cell => cell.value));
  const hotDataMX = arra_mx.map(row => row.map(cell => cell.value));








  useEffect(() => {

    getProductionDataFN();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

    if (production_data && production_data.length > 0) {


      setarra(JSON.parse(production_data[0]['budget_usa']));
      setarra_mx(JSON.parse(production_data[0]['budget_mx']));

      /* let parsed = JSON.parse(production_data[0]['budget_usa']);
       //add extra column      
       parsed.forEach(element => {
         element.push({ value: "" });
       });
       setarra(parsed);
       */

    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [production_data]);



  useEffect(() => {

    if (production_data_edited) {

      setissaving(false);
      resetCreationFN();

    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [production_data_edited]);



  const handleAfterChange = (changes, source) => {
    console.log(source);
    if (source === 'edit' || source === 'CopyPaste.paste' || source === 'Autofill.fill') { // Handle both edit and paste
      const newData = [...arra];
      changes.forEach(([row, prop, oldValue, newValue]) => {
        if (newData[row] && newData[row][prop]) { // Ensure the cell exists
          newData[row][prop] = { value: newValue }; // Update the 'value' property
        }
      });
      setarra(newData);
    }
  };


  const handleAfterChangeMX = (changes, source) => {
    if (source === 'edit' || source === 'CopyPaste.paste' || source === 'Autofill.fill') { // Handle both edit and paste
      const newData = [...arra_mx];
      changes.forEach(([row, prop, oldValue, newValue]) => {
        // Update the 'value' property in the JSON array

        newData[row][prop] = { value: newValue };

      });

      //console.log(newData);

      setarra_mx(newData);
    }
  };


  const LabelRenderer = (props) => {
    const { value } = props;

    return <span style={{ fontWeight: "600", width: "100%" }}>{value}</span>;
  };


  return token ? (
    <div className="container" style={{ marginTop: "90px" }}>

      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column"
          style={{
            color: "black",
            padding: "10px !important",
            //border: "2px solid #FECB00",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.2)',


          }}
        >
          <div style={{ height: "15px" }}></div>
          <h1 style={{ padding: "0 20px" }}>Edit Production Cost Data Prices</h1>
          <p style={{ padding: "0 20px", fontSize: "0.8em" }}>Only edit UNITS and/or RATE</p>
          <div style={{ height: "15px" }}></div>

          <Tabs onSelect={(index) => setwhat(index === 0 ? "us" : "mx")}>
            <TabList style={{ padding: "0 20px" }}>
              <Tab>US DATA</Tab>
              <Tab>MX DATA</Tab>
            </TabList>

            <TabPanel>

              <div style={{ padding: "5px 20px" }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#8F8EFF",
                    borderRadius: "3px",
                  }}
                  onClick={() => {
                    //budgetPop.current.open()
                    setissaving(true);
                    editProductionDataFN(match.params.id, arra, what);
                  }}
                  disabled={issaving}
                >
                  {issaving ? <img alt="loading.." src={LOADER} style={{ height: "15px" }} /> : <b>Save US Data</b>}
                </button></div>


              <div style={{ height: "5px" }}></div>

              <HotTable
                data={hotData}
                formulas={{
                  engine: hyperformulaInstance,
                  // [plugin configuration]
                }}
                rowHeaders={true}
                licenseKey="non-commercial-and-evaluation"
                width="100%"
                colHeaders={[
                  "ACCOUNT NO", "DESCRIPTION", "QUANTITY", "FREQUENCY", "RATE", "SUBTOTAL", "SUBTOTAL($)", "NOTES", "X"
                ]}
                autoColumnSize={true}
                afterChange={handleAfterChange}
              >
                <HotColumn data={0} />
                <HotColumn data={1} width={300}>
                  <LabelRenderer hot-renderer />
                </HotColumn>
                <HotColumn data={2} />
                <HotColumn data={3} />
                <HotColumn data={4} />
                <HotColumn data={5} />
                <HotColumn data={6} />
                <HotColumn data={7} />
                <HotColumn data={8} />

              </HotTable>

            </TabPanel>

            <TabPanel>
              <div style={{ padding: "5px 20px" }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#8F8EFF",
                    borderRadius: "3px",
                  }}
                  onClick={() => {
                    //budgetPop.current.open()
                    setissaving(true);
                    editProductionDataFN(match.params.id, arra_mx, what);
                  }}
                  disabled={issaving}
                >
                  {issaving ? <img alt="loading.." src={LOADER} style={{ height: "15px" }} /> : <b>Save MX Data</b>}
                </button></div>


              <div style={{ height: "5px" }}></div>

              <HotTable
                data={hotDataMX}
                formulas={{
                  engine: hyperformulaInstance,
                  // [plugin configuration]
                }}
                rowHeaders={true}
                licenseKey="non-commercial-and-evaluation"
                width="100%"
                colHeaders={[
                  "ACCOUNT NO", "DESCRIPTION", "QUANTITY", "FREQUENCY", "RATE", "SUBTOTAL", "SUBTOTAL($)", "NOTES", "X"
                ]}

                afterChange={handleAfterChangeMX}
                autoColumnSize={true}


              >
                <HotColumn data={0} />
                <HotColumn data={1} width={300}>
                  <LabelRenderer hot-renderer />
                </HotColumn>
                <HotColumn data={2} />
                <HotColumn data={3} />
                <HotColumn data={4} />
                <HotColumn data={5} />
                <HotColumn data={6} />
                <HotColumn data={7} />
                <HotColumn data={8} />
              </HotTable>


            </TabPanel>

          </Tabs>


        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2024 filmica</p>
        <div style={{ height: "65px" }}></div>

      </div>








    </div >) : <Redirect to="/login" />;

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    balance: state.home.balance,
    production_data: state.home.production_data,
    production_data_edited: state.home.production_data_edited




  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    editProductionDataFN: (a, b, c) =>
      dispatch(editProductionData(a, b, c)),

    getProductionDataFN: (a) =>
      dispatch(getProductionData(a)),


    resetCreationFN: () =>
      dispatch(resetCreation()),


  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(AdminData);
