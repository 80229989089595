import { Fragment, useEffect } from 'react';

import { Router, Switch, Route, useLocation } from "react-router-dom";


import { createBrowserHistory } from "history";

import { useDispatch } from 'react-redux';



import Navbar from "./components/Navbar";
import ScrollToTop from "./components/ScrollToTop";

//import Home from "./pages/Home";
//import LP from "./pages/LP";

import Login from "./pages/Login";
import Recovery from "./pages/Recovery";
import Register from "./pages/Register";


import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";


import MyScreenPlays from "./pages/MyScreenPlays";
import Budgets from "./pages/Budgets";
import Budget from "./pages/Budget";

import Breakdowns from "./pages/Breakdowns";


import CreateScreenPlay from "./pages/CreateScreenPlay";
import ImportScreenPlay from "./pages/ImportScreenPlay";
import ScreenPlay from "./pages/ScreenPlay";
import Billing from "./pages/Billing";
import Settings from "./pages/Settings";
import Support from "./pages/Support";
import AdminDashboard from "./pages/AdminDashboard";
import AdminUsers from "./pages/AdminUsers";
import AdminPayments from "./pages/AdminPayments";
import ManageUser from "./pages/ManageUser";
import ScreenAdmin from "./pages/ScreenAdmin";
import AdminAnalytics from "./pages/AdminAnalytics";
import AdminSettings from "./pages/AdminSettings";
import AutoLogin from "./pages/AutoLogin";
import Feedback from "./pages/Feedback";
import AdminData from "./pages/AdminData";


import { getBalance } from "./store/actions/Actions";







const history = createBrowserHistory();




function Main() {

  const dispatch = useDispatch();
  const location = useLocation();

  const getBackgroundColor = (pathname) => {
    if (pathname === '/' || pathname.includes('/login') || pathname.includes('/register') || pathname.includes('/recovery')) {
      return '#0F1028';
    } else {
      return '#E5E6EC';
    }
  };

  /*
  const getBackgroundColor = (pathname) => {
    switch (pathname) {
      case '/login':
        return '#0F1028';
      case '/register':
        return '#0F1028';
      case '/recovery':
        return '#0F1028';
      default:
        return '#E5E6EC';
    }
  };

  */


  useEffect(() => {
    if (!location.pathname.includes("register") && !location.pathname.includes("login")) {
      //alert(1)
      dispatch(getBalance());

    }
  }, [dispatch, location.pathname]);

  useEffect(() => {
    document.documentElement.style.backgroundColor = getBackgroundColor(location.pathname);
    console.log(location.pathname, getBackgroundColor(location.pathname));
  }, [location.pathname]);


  return (


    <div className="App">
      {location.pathname !== '/' && location.pathname !== '/login' && !location.pathname.includes('/register') && location.pathname !== '/recovery' && <Navbar />}



      <Fragment>
        <ScrollToTop />
        <Switch>
          {/*<Route exact path="/" component={LP} />*/}

          <Route exact path="/" component={Login} />
          <Route exact path="/screenplays" component={MyScreenPlays} />
          <Route exact path="/budgets" component={Budgets} />
          <Route exact path="/budget/:id" component={Budget} />

          <Route exact path="/breakdowns" component={Breakdowns} />



          <Route exact path="/create" component={CreateScreenPlay} />
          <Route exact path="/import" component={ImportScreenPlay} />

          <Route exact path="/edit/:id" component={ScreenPlay} />
          <Route exact path="/billing/:id?" component={Billing} />
          <Route exact path="/autologin/:id?" component={AutoLogin} />



          <Route exact path="/settings" component={Settings} />
          <Route exact path="/support" component={Support} />
          <Route exact path="/feedback" component={Feedback} />

          <Route exact path="/admin" component={AdminDashboard} />
          <Route exact path="/admin/users" component={AdminUsers} />
          <Route exact path="/admin/users/:id" component={ManageUser} />
          <Route exact path="/admin/users/screen/:id" component={ScreenAdmin} />
          <Route exact path="/admin/payments" component={AdminPayments} />
          <Route exact path="/admin/analytics" component={AdminAnalytics} />
          <Route exact path="/admin/settings" component={AdminSettings} />
          <Route exact path="/admin/data" component={AdminData} />





          <Route exact path="/login" component={Login} />
          <Route exact path="/recovery" component={Recovery} />
          <Route exact path="/register/:referral_code?" component={Register} />



          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy" component={Privacy} />

        </Switch>
      </Fragment>
    </div>
  );
}

function App() {
  return (
    <Router history={history}>
      <Main />
    </Router>
  );
}



export default App;
