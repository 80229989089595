import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { loginforced } from "../store/actions/Actions";
import GIFFE from "../assets/images/loader.svg";



function AutoLogin(props) {
  const { match, loginforcedFN } = props;


  useEffect(() => {
    // Update the document title using the browser API
    loginforcedFN(match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container" style={{ marginTop: "60px" }}>
      <div className="columns" style={{ margin: 13, }}>
        <div
          className="column"
          style={{
            color: "#3C2200",
            padding: "10px !important",
            textAlign: "center"
          }}
        >
          <div style={{ height: "10px" }}></div>
          <img style={{ height: "100px", textAlign: "center" }} alt="loading" src={GIFFE} />
          <div style={{ height: "20px" }}></div>

          <p>Logging you in, please hold on.</p>




        </div>
      </div>
    </div>
  );
}



const mapDispatchToProps = (dispatch) => {
  return {
    loginforcedFN: (id) =>
      dispatch(loginforced(id)),

  };
};

export default compose(connect(null, mapDispatchToProps))(
  AutoLogin
);
