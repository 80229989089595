import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import LOADER from "../assets/images/loader.svg";

//import Popup from 'reactjs-popup';
//import 'reactjs-popup/dist/index.css';

import { createScreenplay, resetCreation, importScreenplay } from "../store/actions/Actions";

import { Redirect } from 'react-router-dom';


import { useHistory } from 'react-router-dom';

function CreateScreenPlay(props) {


  const history = useHistory();
  const { token, new_screen_id, createScreenplayFN, resetCreationFN, importScreenplayFN } = props;


  const [title, settitle] = useState("");
  const [author, setauthor] = useState("");

  const [doingwhat, setdoingwhat] = useState("");



  const [file, setFile] = useState(null);

  const [isimport, setisimport] = useState(false);

  const [isloading, setisloading] = useState(false);
  const [errore, seterrore] = useState("");




  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setFile(selectedFile);
      seterrore('');
    } else {
      seterrore("Only PDF files are allowed.");
      setFile(null);
    }
  };


  useEffect(() => {

    if (new_screen_id) {

      if (isimport) {

        resetCreationFN();
        setisimport(false);
        history.push({
          pathname: '/screenplays',
        })

      }

      else {

        resetCreationFN();
        history.push({
          pathname: '/edit/' + new_screen_id,
        })
      }

    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [new_screen_id]);



  return (
    !token ? <Redirect to="/login" /> : <div className="container" style={{ marginTop: "90px" }}>

      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column padd"
          style={{
            color: "black",
            padding: "20px 110px !important",
            //border: "2px solid #FECB00",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.20)',


          }}
        >
          <h1>Create New ScreenPlay</h1>

          <p style={{ color: "#909090" }}>Enter a name for your new movie:</p>
          <div style={{ height: "15px" }}></div>

          <div className="field">
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Enter a name"}
                id="title"
                name="title"
                onChange={(v) => {
                  setdoingwhat("create");
                  settitle(v.target.value);
                }}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-star"></i>
              </span>
            </p>
          </div>

          <div className="field">
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Enter an author name"}
                id="author"
                name="author"
                onChange={(v) => {
                  setauthor(v.target.value);
                }}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-user"></i>
              </span>
            </p>
          </div>

          <div style={{ height: "15px" }}></div>



          <div className="field">
            <button
              style={{
                padding: "10px 15px",
                fontSize: "1.1rem",
                fontWeight: "600",
                border: 0,
                color: "#fff",
                background: "#8F8EFF",
                borderRadius: "3px",
              }}
              onClick={() => {
                if (title === "" || !title) {
                  setisloading(false);
                  return seterrore("Please enter a valid screenplay name");

                }

                if (author === "" || !author) {
                  setisloading(false);
                  return seterrore("Please enter a valid author name");

                }


                setisloading(true)
                createScreenplayFN(title, author);
              }}
            >

              {isloading && !isimport ? <img alt="loading.." src={LOADER} style={{ height: "20px" }} /> : <b>Create Screenplay</b>}

            </button>
            {<p style={{ color: "red", marginTop: "20px" }}>{doingwhat === "create" ? errore : null}</p>}
          </div>






        </div>

      </div>


      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>


        <div
          className="column padd"
          style={{
            color: "black",
            padding: "20px 110px !important",
            //border: "2px solid #FECB00",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.20)',


          }}
        >
          <h1>Import New ScreenPlay</h1>

          <p style={{ color: "#909090" }}>Enter a name for your new movie:</p>
          <div style={{ height: "15px" }}></div>

          <div className="field">
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Enter a name"}
                id="title"
                name="title"
                onChange={(v) => {
                  settitle(v.target.value);
                }}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-star"></i>
              </span>
            </p>
          </div>

          <div className="field">
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Enter an author name"}
                id="author"
                name="author"
                onChange={(v) => {
                  setauthor(v.target.value);
                }}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-user"></i>
              </span>
            </p>
          </div>

          <div className="field">
            <p className="control has-icons-left">


              <input
                required
                accept="application/pdf"
                className="input"
                type="file"
                placeholder={"Select a PDF screenplay"}
                id="file"
                name="file"
                onChange={handleFileChange}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-file"></i>
              </span>
            </p>
          </div>



          <div style={{ height: "15px" }}></div>



          <div className="field">
            <button
              style={{
                padding: "10px 15px",
                fontSize: "1.1rem",
                fontWeight: "600",
                border: 0,
                color: "#fff",
                background: "#8F8EFF",
                borderRadius: "3px",
              }}
              onClick={() => {
                if (title === "" || !title) {
                  setisloading(false);
                  return seterrore("Please enter a valid screenplay name");

                }

                if (author === "" || !author) {
                  setisloading(false);
                  return seterrore("Please enter a valid author name");

                }

                if (file === "" || !file) {
                  setisloading(false);
                  return seterrore("Please select a PDF file");

                }

                setisimport(true);
                setisloading(true);
                importScreenplayFN(title, author, file);
              }}
            >

              {isloading && isimport ? <img alt="loading.." src={LOADER} style={{ height: "20px" }} /> : <b>Import Screenplay</b>}

            </button>
            {<p style={{ color: "red", marginTop: "20px" }}>{doingwhat !== "create" ? errore : null}</p>}
          </div>






        </div>



      </div>
      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2024 filmica</p>
      </div>





    </div >);

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    error_in_creation: state.home.error_in_creation,
    new_screen_id: state.home.new_screen_id



  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    createScreenplayFN: (v, v2) =>
      dispatch(createScreenplay(v, v2)),

    resetCreationFN: () =>
      dispatch(resetCreation()),

    importScreenplayFN: (v, v2, v3) =>
      dispatch(importScreenplay(v, v2, v3)),


  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(CreateScreenPlay);
