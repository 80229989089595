import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getAnalytics } from "../store/actions/Actions";
import { Redirect } from 'react-router-dom';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';


import DateRangePicker from '@wojtekmaj/react-daterange-picker';







function AdminAnalytics(props) {

  const { token, getAnalyticsFN, analytics } = props;



  const [daterange, setdaterange] = useState([new Date(), new Date()]);


  useEffect(() => {



    getAnalyticsFN(daterange);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics]);


  const handleSelect = date => {
    console.log(date); // native Date object
    setdaterange(date);
    getAnalyticsFN(date);

  };


  const conv_rate =analytics && analytics.paid_users && parseFloat(analytics.paid_users[0]['paid_users'] / analytics.users[0]['user_base'] * 100);

  return token ? (
    <div className="container" style={{  marginTop: "80px" }}>


      <div className="container2">

        <div className="box2"><DateRangePicker onChange={handleSelect} value={daterange} style={{ color: "#000" }} /></div>

        <div className="box2"><p>Revenue</p><h1 style={{ fontWeight: 600 }}>${analytics && analytics.revenue && analytics.revenue.length > 0 && analytics.revenue[0]['revenue'] ? analytics.revenue[0]['revenue'] : 0}</h1></div>
        <div className="box2"><p>Screenplay Generated</p><h1 style={{ fontWeight: 600 }}>{analytics && analytics.screens && analytics.screens.length > 0 && analytics.screens[0]['screens_generated']}</h1></div>
        <div className="box2"><p>New Users</p><h1 style={{ fontWeight: 600 }}>{analytics && analytics.users && analytics.users.length > 0 && analytics.users[0]['user_base']}</h1></div>
        <div className="box2"><p>Conversion Rate</p><h1 style={{ fontWeight: 600 }}>{analytics && isNaN(conv_rate) ? "0" : conv_rate.toFixed(2)}%</h1></div>

      </div>


      <div className="container2">



      </div>

      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2024 filmica</p>
      </div>

    </div >) : <Redirect to="/login" />;

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    total: state.home.total,
    page: state.home.page,
    limit: state.home.limit,
    loading: state.home.loading,
    analytics: state.home.analytics
  };
};

const mapDispatchToProps = (dispatch) => {
  return {



    getAnalyticsFN: (a) =>
      dispatch(getAnalytics(a)),


  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(AdminAnalytics);
