import React from "react";
import { useLocation } from "react-router-dom";
import Links from "./Links";
import LinksAdmin from "./LinksAdmin";

const Navbar = () => {
  const location = useLocation();
  return location.pathname.startsWith("/admin") ? <LinksAdmin /> : <Links />;
};

export default Navbar;
